* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 400;
  src: url(./assets/fonts/Noto_Sans/NotoSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  src: url(./assets/fonts/Noto_Sans/NotoSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans Arabic';
  font-weight: 400;
  src: url(./assets/fonts/Noto_Sans_Arabic/NotoSansArabic-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans Arabic';
  font-weight: 700;
  src: url(./assets/fonts/Noto_Sans_Arabic/NotoSansArabic-Bold.ttf) format('truetype');
}

html, body, #root {
  width: 100%;
  height: 100%;
}

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

body {
  

  font-family: "Noto Sans", "Noto Sans Arabic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* direction: rtl; */
  font-size: large;
}

code {
  font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New",
    monospace;
}

:root {
  --clrBtn: whitesmoke;
  --clrBg: rgb(34, 111, 199);
  --error: #d32f2f;
}






